<template>
  <div>
    <b-card header-tag="header">
      <template #header>
        <b-row>
          <b-col cols="12">
            <h5 class="mb-0">{{$route.name}}</h5>
          </b-col>
          <!-- <b-col cols="2">
            <div class="d-flex">
              <b-btn variant="secondary" :href="'#/orders/' + $route.params.id + '/edit'" class="ml-auto">Edit</b-btn>
            </div>
          </b-col> -->
        </b-row>
      </template>
      <b-row>
        <template v-for="(m, index) in model">
          <b-col :key="index" cols="12" v-if="(typeof m.disabled === 'undefined' || !m.disabled) && m.type !== 'hidden'">
            <detail-group-input :key="index" :type="m.type" :label="typeof m.label === 'string' ? m.label : null" :value="m.value" :id="index">
              <template slot="content_package_id" slot-scope="defaultSlotScope">
                <router-link :to="'/packages/' + defaultSlotScope.colData.id" target="_blank">{{ defaultSlotScope.colData.name }}</router-link>
              </template>
              <template slot="content_payment_proof_url" slot-scope="defaultSlotScope">
                <a v-if="defaultSlotScope.colData !== null" :href="defaultSlotScope.colData" target="_blank">Download</a>
              </template>
              <template slot="content_notification_status" slot-scope="defaultSlotScope">
                <p>
                  <b-badge variant="secondary" v-if="defaultSlotScope.colData === 'pending'">Pending</b-badge>
                  <b-badge variant="secondary" v-if="defaultSlotScope.colData === 'sending'">Sending</b-badge>
                  <b-badge variant="success" v-else-if="defaultSlotScope.colData === 'succeed'">Sukses</b-badge>
                  <b-badge variant="danger" v-else-if="defaultSlotScope.colData === 'failed'">Gagal</b-badge>
                  <b-button size="sm" class="ml-2" v-if="defaultSlotScope.colData === 'failed'" @click.prevent="resendNotif">Kirim Ulang</b-button>
                  <b-button size="sm" class="ml-2" v-if="defaultSlotScope.colData === 'failed'" @click.prevent="reCheckStatus">Cek Ulang Status</b-button>
                </p>
              </template>
            </detail-group-input>
          </b-col>
        </template>
      </b-row>
    </b-card>

    <b-card class="card-with-tab" >
      <order-details v-if="tabIndex === 0" :details-data="orderDetailsData"/>
      <!-- <payments v-else-if="tabIndex === 1" :order-id="$route.params.id" @afterSubmit="afterSubmitPayment" @afterDelete="afterDeletePayment"/>
      <accordion-pilgrim-document v-else-if="tabIndex === 2"/> -->
    </b-card>
    <b-card style="margin-top: -25px;">
      <div class="w-100">
        <b-button :href="'#/orders'" variant="secondary" class="ml-3 mt-3 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Kembali</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { setModelValue, formatModelValue } from '@/_helpers'
import detailGroupInput from '@/components/DetailGroupInput.vue'
import orderDetails from '@/pages/Order/Components/OrderDetails.vue'
import payments from '@/pages/Order/Components/Payments.vue'
import pilgrimDocuments from '@/pages/Order/Components/PilgrimDocuments.vue'
import accordionPilgrimDocument from '@/pages/Order/Components/AccordionPilgrimDocument.vue'
import Order from '@/models/Order.js'
import Agent from '@/models/Agent.js'

const orderModel = new Order()
orderModel.addTemporaryField( 'order_no', { type: 'text', label: 'No. Order', rules: null})
orderModel.addTemporaryField( 'created_at', { type: 'date', label: 'Tanggal', rules: null})
orderModel.addTemporaryField( 'payment_proof_url', { type: 'date', label: 'Bukti Pembayaran', rules: null})
orderModel.addTemporaryField( 'customer_name', { type: 'text', label: 'Nama Customer', rules: null})
orderModel.addTemporaryField( 'customer_phone_number', { type: 'Htp Customer', label: 'Tanggal', rules: null})
orderModel.addTemporaryField( 'cs_name', { type: 'text', label: 'CS', rules: null})
orderModel.addTemporaryField( 'notification_status', { type: 'text', label: 'Notif Status', rules: null})

const agentModel = new Agent()

export default {
  components: {
    detailGroupInput,
    pilgrimDocuments,
    accordionPilgrimDocument,
    orderDetails,
    payments
  },
  watch: {
    '$route.params.id': function (val) {
      if (val !== undefined) {
        this.getDetail()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      model: orderModel.init(),
      orderDetailsData: [],
      tabIndex: 0
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    formatCurrency (value) {
      return formatModelValue('price', value)
    },
    resendNotif () {
      this.$store.dispatch('notification/info', 'Sedang mengirim notifikasi.')
      this.$http.post(process.env.VUE_APP_API_URL + 'orders/send-wa-message', {
        id: this.$route.params.id
      }).then(response => {
        this.$store.dispatch('notification/clear')
        this.$store.dispatch('notification/success', 'Notifikasi berhasil dikirim.')
        this.getDetail()
      }).catch(error => {
        this.$store.dispatch('notification/clear')
        this.$store.dispatch('notification/error', 'Gagal mengirim notifikasi.')
        console.log(error)
      })
    },
    reCheckStatus () {
      this.$store.dispatch('notification/info', 'Sedang check ulang status.')
      this.$http.post(process.env.VUE_APP_API_URL + 'orders/check-single-message-status', {
        id: this.$route.params.id
      }).then(response => {
        this.$store.dispatch('notification/clear')
        this.$store.dispatch('notification/success', 'Notifikasi berhasil dikirim.')
        this.getDetail()
      }).catch(error => {
        this.$store.dispatch('notification/clear')
        this.$store.dispatch('notification/error', 'Gagal check ulang status.')
        console.log(error)
      })
    },
    getDetail () {
      this.isLoading = true
      orderModel.find(this.$route.params.id).then(resp => {
        this.isLoading = false
        this.orderDetailsData = resp.details
        // setModelValue(this.model, resp)
        this.model.order_no.value = resp.order_no
        this.model.created_at.value = resp.created_at
        this.model.customer_name.value = resp.customer.name
        this.model.customer_phone_number.value = resp.customer.phone_number
        // this.model.email.value = resp.customer.email
        this.model.notification_status.value = resp.notification_status
        this.model.cs_name.value = resp.agent.name
        this.model.payment_proof_url.value = resp.payment_proof_url

        // this.model.status.value = resp.status
        // this.model.total.value = resp.total
        // this.model.total_paid.value = resp.total_paid
        // this.model.pilgrim_name.value = resp.pilgrims[0].name
        // this.model.pilgrim_nik.value = resp.pilgrims[0].nik
        // this.model.pilgrim_phone_number.value = resp.pilgrims[0].phone_number
        // resp.details.forEach(detail => {
        //   if(detail.orderdetailable_type === 'App\\Models\\Package') {
        //     this.model.package_id.value = detail.orderdetailable
        //   }
        // })
        // if (resp.agent_id !== null) {
        //   this.getAgentDetail(resp.agent_id)
        // }
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        this.isLoading = false
      })
    },
    getAgentDetail (id) {
      agentModel.find(id).then(resp => {
        this.model.agent_id.value = resp
      })
    },
    afterSubmitPayment (resp) {
      this.getDetail()
    },
    afterDeletePayment () {
      this.getDetail()
    }
  }
}
</script>