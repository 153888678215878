<template>
  <b-row>
    <div class="col-sm-12" id="paginated-table">
      <el-table ref="paginatedTable" border stripe style="width: 100%;" :data="detailsData" empty-text="Data tidak ditemukan" resizable>
        <el-table-column prop="orderdetailable" label="Nama">
          <template slot-scope="scope">
            {{ scope.row.package.name  }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="orderdetailable" label="Url Download" align="center">
          <template slot-scope="scope">
            <a v-if="scope.row.package.url_download !== null" :href="scope.row.package.url_download" target="_blank">Download</a>
            <p v-else>-</p>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </b-row>
</template>

<script>
import { formatModelValue } from '@/_helpers'

export default {
  props: {
    detailsData: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatCurrency (value) {
      return formatModelValue('price', value)
    }
  }
}
</script>