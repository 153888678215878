// import axios from 'axios'
import BaseModel from '@/models/BaseModel.js'

export default class Package extends BaseModel {
  fields =  {
    'name': {
      type: 'text',
      label: 'Nama',
      rules: 'required'
    },
    'url_download': {
      type: 'text',
      label: 'Url Download',
      rules: 'required'
    },
    // 'package_category_id': {
    //     type: 'enum',
    //     rules: null,
    //     options: [],
    //     label: 'Kategori'
    // },
    // 'price': {
    //   type: 'price',
    //   label: 'Harga',
    //   rules: 'required'
    // },
    // 'seat_available': {
    //   type: 'number',
    //   label: 'Kuota Tersedia',
    //   rules: 'required'
    // },
    // 'departure_date': {
    //   type: 'month',
    //   label: 'Bulan Keberangkatan',
    //   rules: 'required'
    // }
  }
  endpoint = process.env.VUE_APP_API_URL + 'packages'
}