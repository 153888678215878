<template>
    <b-card header-tag="header">
        <template #header>
            <h5 class="mb-0">{{$route.name}}</h5>
        </template>

        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
              <form-group-input placeholder="No WA" :need-label="true" label="No WA" v-model="model.customer_phone_number" @blur="onBlurWaNumberField" rules="required" id="customer_phone_number" autofocus />
              <!-- <div class="mb-3">
                <label class="form-label">No Wa  <span class="text-danger">*</span></label>
                <v-select v-model="model.customer_list" label="phone_number" :filterable="false" :options="list.customers" @search="onSearch" @option:selected="onCustomerSelected" @input="onCustomerSelected">
                  <template slot="no-options">
                    cari nomor wa customer
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.phone_number.replace('+62', 0) }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.phone_number.replace('+62', 0) }}
                    </div>
                  </template>
                </v-select>
                <form-group-input class="no_wa_text" placeholder="" :need-label="false" label="" v-model="model.customer_phone_number" rules="required" id="customer_phone_number" />
              </div> -->
              <form-group-input placeholder="Nama" :need-label="true" label="Nama" v-model="model.customer_name" rules="required" id="customer_name" />
              <form-group-input placeholder="Bukti Transfer" :need-label="true" label="Bukti Transfer" v-model="model.payment_proof" rules="required" type="attachment" id="payment_proof" />
              <b-form-group label="Produk Dibeli" class="mb-0">
                <b-form-checkbox-group
                  v-model="model.package_ids"
                  :options="list.packages"
                  name="flavour-2a"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
              <p style="color: red;" class="mt-3"><strong>Pastikan Telah Memilih Produk Yang Benar</strong></p>
              <form-group-input class="package_ids_text" placeholder="package_ids" :need-label="false" label="package_ids" :value="model.package_ids.join(',')" rules="required" type="text" id="package_ids" />
              <div>
                <b-button type="submit" variant="primary" class="mt-3"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Simpan</b-button>
                <b-button :href="'#/orders'" variant="secondary" class="ml-3 mt-3 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> Batal</b-button>
              </div>
          </form>
        </ValidationObserver>
      </b-card>
</template>

<script>
import { saveProcess, setModelValue, scrollToError, objectToOptions, formatModelValue } from '@/_helpers'
import formGroupInput from '@/components/FormGroupInput.vue'
import Order from '@/models/Order.js'
import Package from '@/models/Package.js'
import Agent from '@/models/Agent.js'

const orderModel = new Order()
const packageModel = new Package()
const agentModel = new Agent()

export default {
  components: {
    formGroupInput
  },
  watch: {
    '$route.params.id': function (val) {
      if (this.mode === 'update') {
        this.getDetail()
      }
    }
  },
  computed: {
    mode: function () {
      return this.$route.params.id !== undefined ? 'update' : 'create'
    }
  },
  data () {
    return {
      isLoading: false,
      list: {
        customers: [],
        packages: []
      },
      model: {
        customer_list: null,
        customer_name: null,
        customer_email: null,
        customer_phone_number: null,
        payment_proof: null,
        package_ids: []
      }
    }
  },
  mounted () {
    this.getPackage()
    // this.getUserAgents()
    if (this.mode === 'update') {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      orderModel.find(this.$route.params.id).then(resp => {
        
      }).catch(error => {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
      })
    },
    getPackage () {
      packageModel.list({
        'is_active': 1
      }).then(resp => {
        this.list.packages = []
        resp.data.forEach(d => {
          this.list.packages.push({ text: d.name, value: d.id })
        })
      })
    },
    onCustomerSelected (data) {
      if (data !== null) {
        this.model.customer_phone_number = data.phone_number
        this.model.customer_name = data.name
      } else {
        this.model.customer_phone_number = null
        this.model.customer_name = null
      }
    },
    onBlurWaNumberField (e) {
      this.$http.get(process.env.VUE_APP_API_URL + 'customers' + '?phone_number=' + e.target.value).then(response => {
        if (response.data.data.length > 0) {
          this.model.customer_name = response.data.data[0].name
        }
      }).catch(error => {
      })
    },
    onSearch(search, loading) {
      if(search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      vm.$http.get(process.env.VUE_APP_API_URL + 'customers' + '?phone_number=' + escape(search)).then(response => {
        vm.list.customers = response.data.data
        loading(false);
      }).catch(error => {
        loading(false);
      })
    }, 350),
    resetForm () {
      this.$nextTick(() => {
        Object.keys(this.model).forEach(key => {
          this.model[key] = null
        })
        this.model.package_ids = []
        this.$refs.form.reset()
      })
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form)
          return false
        }
        this.isLoading = true;
        
        let formData = new FormData()
        formData.append('customer[name]', this.model.customer_name)
        formData.append('customer[phone_number]', this.model.customer_phone_number)
        formData.append('payment_proof', this.model.payment_proof)
        this.model.package_ids.forEach(package_id => {
          formData.append('details[][package_id]', package_id)
        });
        orderModel.create(formData).then(resp => {
          this.isLoading = false
          // this.resetForm()
          this.$store.dispatch('notification/success', 'Data order berhasil disimpan.')
          this.$router.push('/orders')
        }).catch(error => {
          this.isLoading = false
          this.$store.dispatch('notification/error', error)
        })
        // saveProcess(this.model, this.mode === 'update').then(model => {
        //   console.log(model);
        //   this.isLoading = true
        //   if (this.mode === 'update') {
        //     orderModel.update(this.$route.params.id, {
        //       "package_id": model.package_id,
        //       "agent_id": model.agent_id,
        //       "pilgrims": [
        //         {
        //           "name": model.pilgrim_name,
        //           "nik": model.pilgrim_nik,
        //           "phone_number": model.pilgrim_phone_number
        //         }
        //       ]
        //     }).then(() => {
        //       this.isLoading = false
        //       this.$store.dispatch('notification/success', 'Data order berhasil diubah.')
        //       this.$router.push('/orders/' + this.$route.params.id)
        //     }).catch(error => {
        //       this.isLoading = false
        //       this.$store.dispatch('notification/error', error)
        //     })
        //   } else {
        //     orderModel.create({
        //       "package_id": model.package_id,
        //       "agent_id": model.agent_id,
        //       "pilgrims": [
        //         {
        //           "name": model.pilgrim_name,
        //           "nik": model.pilgrim_nik,
        //           "phone_number": model.pilgrim_phone_number
        //         }
        //       ]
        //     }).then(resp => {
        //         this.isLoading = false
        //         // this.resetForm()
        //         this.$store.dispatch('notification/success', 'Data order berhasil disimpan.')
        //         this.$router.push('/orders/' + resp.id)
        //       }).catch(error => {
        //         this.isLoading = false
        //         this.$store.dispatch('notification/error', error)
        //     })
        //   }
        // }).catch(() => {})
      })
    }
  }
}
</script>